/*Header-- template applied from Classic/Taymor*/
@media only screen and (min-width: 960px) {
.header-top-container {
    background-color: var(--orange);
    text-transform: uppercase;
}
}
.custom-freeshipping-note a, .links>li>a, .std p.welcome-msg, .userCode, .userName, .welcome-msg *, .header .userSwitch a {
    color: var(--white) !important;
    font-weight: 400;
    font-family: var(--mainFont) !important;
}
.links>li>a:hover {
    color: var(--black) !important;
}
i.fa.fa-search {
    background-color: var(--orange);
}
i.fa.fa-search:hover {
    background-color: var(--black);
}
#root-wrapper a:hover span.fa:before, .nav-regular .nav-item.level0:hover>a>span, .desktop-main-header-container #mini-cart .fa.fa-shopping-cart:hover {
    color: var(--orange);
}
.nav-regular li.level0>a>span {
    font-family: var(--mainFont);
}
.header .userSwitch:hover a {
    color: var(--black) !important;
    font-weight: 400;
    font-family: var(--mainFont) !important;
}